@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');


/*primary css start*/
/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

html,


a {
  color: var(--secondary-color);
  transition: background, color, border-color, .4s ease;
  text-decoration: none;
}

body {
 line-height: 1.45;
  font-size: 18px;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: #0F0909;
  background: #fff; 
  width: 100%;
  overflow-x: hidden;

}

html,
body {
  height: 100%; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ubuntu', sans-serif;
  color: #237CB2;
  font-weight: 400; 
    line-height: 1.25;
}

.list {
  list-style: none;
  margin: 0px;
  padding: 0px; }

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out; 
}
  a:hover, a:focus {
    text-decoration: none;
    outline: none; }

button:focus {
  outline: none;
  box-shadow: none; }

.overflow-hidden {
  overflow: hidden; }

.sec-title{
  margin-bottom: 35px;
  font-weight: 500;
}

b, sup, sub, u, del {
  color: #0F0909; }

h1 {
  font-size: 60px; }

h2 {
  font-size: 44px; }

h3 {
  font-size: 36px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 22px; }

h6 {
  font-size: 18px; }

img {
  max-width: 100%;
}
p a{
  color:#31B2E9 ;
  font-weight: 600;
}
p a:hover{
  color: #FF6145;
}
@media (max-width: 991px) {
  body {
    font-size: 16px;
  }
  h1 {
  font-size: 40px; }

h2 {
  font-size: 34px; }

h3 {
  font-size: 26px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 22px; }

h6 {
  font-size: 18px; }

}
.card {
  border: none;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0,0,0,0.10);
  height: 100%;
  background-color: rgba(255, 255, 255, .25);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}
/* Start primary btn css
============================================================================================ */
.primary-link{
  color: #041730;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-right: 17px;
  display: inline-block;
}
.primary-link:hover{
  color: #041730;
}

/* .primary-link:before{
      content: url("../images/link-arrow.svg");
      right:  5px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
        position: absolute;
  } */
  .primary-link::before {top: -0.5px;}
  .primary-link:hover:before{
      right:  0px;
  }
 .primary-link:after {
  content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #041730;
    transition: width .3s;
  }
  .primary-link:hover:after {
    width: 100%;
  }
.primary-btn {
  position: relative;
  overflow: hidden;
  background-color: #041730;
  font-size: 16px;
  color: #fff;
  padding: 0 30px;
  padding-right: 46px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  border: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; 
    white-space: nowrap;
}
.primary-btn:after {
    content: url(../images/btn-arrow.svg);
    margin-left: 10px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    position: absolute;
}
.primary-btn:before {
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: #237CB2;
    opacity: 0;
    -webkit-transform: skew(40deg);
    -moz-transform: skew(40deg);
    -ms-transform: skew(40deg);
    -o-transform: skew(40deg);
    transform: skew(40deg);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; 
}
.primary-btn:hover {
    color: #fff; 
}
.primary-btn:hover:before {
      left: 110%;
      opacity: .3;
}
.primary-btn:hover:after {
    margin-left: 16px;
}


/****************************************
 * NavBAR  css start
 ****************************************/
 .mainNav nav {
    position: fixed;
    width: 100vw;
    padding: 0 50px;
    top: 20px;
    border: 0;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    z-index: 99;
    /* border-bottom: 1px solid rgba(0,0,0,0.1); */
}
.mainNav .navbar-brand svg {
  height: 80px;
  width: 100px;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  transform: scale(1);
}
.sticky-header .navbar-brand svg {
  transform: scale(0.8);
}
.navbar-brand svg rect {
  fill: #b7b7b7;
}
.navbar-brand svg path {
  fill: #fff;
}


.mainNav nav .navbar-nav {
  padding-left: 50px;
  margin-left: auto;
}
.mainNav .navbar-light .navbar-brand {
  padding: 0;
}
.loginSide {
  display: flex;
  align-items: center;
}
.mainNav .navbar-light .navbar-nav .nav-link {
 color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  margin-right: 40px;
  position: relative;
}
.mainNav .navbar-light .navbar-nav .nav-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  left: 0;
  bottom: -10px;
  transform: scale(0);
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
}
.mainNav .navbar-light .navbar-nav .nav-link:hover:after {
  transform: scale(1);
}
 .navbar-light .navbar-nav .nav-link{
 color: rgba(255, 255, 255, 1);
}
.sticky-header.mainNav .navbar-light .navbar-nav .nav-link{
  color: #ffffff;
}
.mainNav .navbar-light .navbar-nav .nav-link svg {
  margin-left: 5px;
}
.mainNav .loginSide .nav-link {
  color: #652EAB;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  margin-right: 40px;
}
 .mainNav .loginSide .nav-link {
  color: rgba(255, 255, 255, 1);
}
.sticky-header.mainNav .loginSide .nav-link{
  color: #ffffff;
}
.mainNav .loginSide .nav-link svg {
  margin-left: 5px;
}

.loginSide .primary-btn {
  background-color: transparent;
  border: 1px solid #652EAB;
  line-height: 20px;
  padding: 14px 30px;
  color: #652EAB;
}
 .loginSide .primary-btn, .sticky-header .loginSide .primary-btn{
  background-color: transparent;
  border: 1px solid #fff;
  color: #ffffff;
}
.loginSide .primary-btn::before {
  background: #fff;
}
 .loginSide .primary-btn:hover {
  color: #fff;
}
.loginSide .primary-btn{
  display: flex;
  align-items: center; 
}
.loginSide .primary-btn svg{
    margin-left: 10px;
    transform: rotate(-90deg);
    margin-bottom: 0;
}
 .mainNav .navbar-light .navbar-nav .nav-link svg path, 
.sticky-header.mainNav .navbar-light .navbar-nav .nav-link svg path, 
 .loginSide .primary-btn svg path,
.sticky-header .loginSide .primary-btn svg path{
  fill: #ffffff;
}
.loginSide .primary-btn:after {display: none;}
.mainNav.sticky-header nav {
  background: #185186;
  top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 1600px) {
  .mainNav nav {
    padding: 0 120px;
  }
  .mainNav nav .navbar-nav {
    padding-left: 140px;
  }
}

/* Your existing styles */

@media (max-width: 786px) {
  .modal-paper {
    width: 100%;
    max-width: none;
  

  /* Adjust modal content */
 
    overflow-y: auto; /* Add scroll if content exceeds the screen height */
    max-height: 80vh; /* Adjust max height as needed */
  }
}

@media (max-width: 1279px) {
  .navbar-brand img {
      width: 90px;
  }
  .mainNav nav,  .mainNav nav {
    padding: 10px 0;
    top: 0;
}
.mainNav.sticky-header nav {
    padding-top: 10px; 
    padding-bottom:10px;
}
  .navbar-light .navbar-toggler {
    background: #fff;
  }
  .mainNav button:focus:not(:focus-visible) {
    box-shadow: none;
  }
  .mainNav nav .navbar-nav {
    padding-left: 20px;
  }
  .loginSide {
    align-items: center;
  }
  .mainNav .navbar-light .navbar-nav .nav-link,
  .mainNav .loginSide .nav-link {
    margin-bottom: 0;
    font-size: 14px;
  }
  .mainNav .navbar-light .navbar-nav .nav-link {
    margin-right: 20px;
  }
  .loginSide .primary-btn {
    font-size: 14px;
  }
}

@media (max-width: 1023.98px) {
 

  .navbar-light .navbar-toggler {
    background: #fff;
  }
  .mainNav button:focus:not(:focus-visible) {
    box-shadow: none;
  }
  .mainNav nav .navbar-nav {
    padding-left: 20px;
  }
  .loginSide {
    align-items: center;
  }
  .mainNav .navbar-light .navbar-nav .nav-link,
  .mainNav .loginSide .nav-link {
    margin-bottom: 0;
    font-size: 14px;
  }
  .mainNav .navbar-light .navbar-nav .nav-link {
    margin-right: 20px;
  }
  .loginSide .primary-btn {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .mainNav .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px;
  }
  .loginSide {
    align-items: flex-start;
    flex-direction: column;
  }
  .mainNav .navbar-light .navbar-nav .nav-link,
  .mainNav .loginSide .nav-link {
    margin-bottom: 15px;
  }
  .mainNav nav .navbar-nav {
    padding-left: 0;
  }
  
}

/****************************************
 * heroBanner Companies css start
 ****************************************/
.heroBanner {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
  background: linear-gradient(180deg, #237CB2 0%, #185186 100%);
  height: 900px;
  overflow: hidden;
}

 @keyframes afterslide {
  0% {
    transform: translateY(20px);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 1;
  }
}





.heroBanner .sec-title {
  margin-bottom: 20px;
}
.heroBanner .sec-title {
  color: #fff;
}

.heroA p {
  font-size: 22px;
  width: 85.7%;
  margin-bottom: 36px;
}
 .heroA p {
  color: #fff;
}
.topImg {
  position: absolute;
  top: 0;
  right: 25%;
}
.bottomImg {
  position: absolute;
  bottom: 0;
  right: 6.5%;
  z-index: 1;
}
.heroB {
  position: relative;
}
.cont-img {
  position: relative;
  z-index: 3;
}
.bgImg-b {
  position: absolute;
  left: 0;
  top: -35px;
  z-index: 0;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(20px);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 1;
  }
}
.bottomImg {  
  animation: 4s ease-out 0s infinite slideInFromBottom;
}

@media (max-width: 991px) {
  .heroBanner {
    height: auto;
    padding-top: 130px;
    padding-bottom: 50px;
  }
  .heroA {
    position: relative;
    z-index: 9;
  }
  .heroBanner .sec-title {
    font-size: 34px;
    line-height: 45px !important;
  }
  .heroA p {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .topImg {
    right: 15%;
    width: 50%;
  }
  .bottomImg {
    right: 15%;
    width: 50%;
  }
  .heroB {
    margin: 30px 0 0;
  }
}



/****************************************
 * pageBanner Companies css start
 ****************************************/
.pageBanner {
  background: #073763;
  padding: 160px 0 100px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.pageBanner .pagebanner-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.1;
}
.pageBanner h2, .pageBanner p {
  color: #ffffff;
}
.pageBanner p {
  margin: 0;
}
.serviceDetails {
  padding: 100px 0;
}
.serviceDetails .card {
  border: none;
  box-shadow:0 0 30px rgba(229, 229, 229, 0.4);
  -webkit-box-shadow: 0 0 30px rgba(229, 229, 229, 0.4);
  -moz-box-shadow: 0 0 30px rgba(229, 229, 229, 0.4);
  padding: 0 15px 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 100%;
}
.serviceDetails .card lord-icon {
  width: 100px;
  height: 100px;
}
.serviceDetails .card p {
  margin: 0;
  font-size: 16px;
}

/****************************************
 * sass Companies css start
 ****************************************/

.about-us {
  padding: 130px 0;
}
.companyLogos {
  margin: 50px 0 0;
}
.viewCase {
  display: flex;
  justify-content: flex-end;
  margin: 35px 0 0;
}

@media (max-width: 767px) {
  .about-us {
      padding: 40px 0;
  }
}

/****************************************
 * get the right css start
 ****************************************/
.get-right {
  padding: 130px 0;
  background-color: rgba(6, 25, 57, 3) ;
}
#get-right {
  background: url('../images/led-code-phone.jpg') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.7; */
 
}
.get-right .sec-title {
  margin-bottom: 69px;
}
.get-right .card {
  background: #FFFFFF;
  border: 1px solid #073763;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 32px;
  height: 100%;
}
.get-right .card:hover{
      box-shadow: 0px 10px 30px rgb(29 140 242 / 35%);
}
.get-right .card h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
}
.get-right .card p {
  margin-bottom: 20px;
  color: #0F0909;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}
.get-right .card p strong {
  /* color: #31B2E9 ; */
  font-weight: 600;
}
.right-side-a {
  text-align: right;
}

@media (max-width: 767px) {
  .get-right {
      padding: 40px 0;
  }
}
/****************************************
 * result-dna css start
 ****************************************/
.result-dna {
  padding: 100px 0;
}
#result-dna{
  background: url('../images/blue-dot.jpg') repeat;
}
.result-dna h2 {
  text-align: center;
}
.res-card {
  border-radius: 10px;
  text-align: center;
  margin: 30px 0 0;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0,0,0,0.10);
  height: 100%;
  background-color: rgba(255, 255, 255, .25);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}
.res-card h5 {
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 30px 0 6px;
}
.res-card p {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
}

@media (max-width: 991px){
  .res-card h5 {
    margin: 10px 0 6px;
}
  .res-card p {
     font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 767px){
  .result-dna {
      padding: 40px 0;
  }
}




/****************************************
 * we-surve css start
 ****************************************/

.we-surve {
  /* background: url(../images/roseclay.png) no-repeat; */
  background-color: #fbfbfb;
  background-size: 100% 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}
.we-surve .sec-title {
  text-align: center;
}
.we-surve .card {
  position: relative;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  cursor: pointer;
  padding: 20px 20px 50px 20px;
  overflow: hidden;
}
.we-surve .card h5 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
    color: #185186;
    text-align: right;
}
.we-surve .card img {
  width: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
}
.we-surve .card span {
  position: absolute;
  font-size: 7em;
  opacity: 0.03;
  text-transform: uppercase;
  font-weight: 600;
  bottom: -50px;
  right: -10px;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
}
.we-surve .card:hover span {
  bottom: 0;
}
.we-surve .card:hover img {
  left: 20px;
}
.we-surve lottie-player {
  height: 400px;
}
@media (min-width: 1200px){
  .we-surve-block:nth-child(odd) {
      padding-right: 40px;
  }
  .we-surve-block:nth-child(even) {
    padding-left: 40px;
  }
}
@media (max-width: 767px){
  .we-surve {
      padding-top: 40px;
      padding-bottom: 40px;
  }
  .we-surve-block {
      margin-bottom: 15px;
      margin-top: 15px;
  }
  .our-goal .primary-link {
    margin-top: 20px;
  }
}

/****************************************
 * Testimonial css start
 ****************************************/
 section.testimonial {
  overflow: hidden;
  max-width: 100%;
  position: relative;
  background: #fbfbfb;
}
.testimonial .sec-title {
    margin-bottom: 60px;
}
div#testimonial {
    padding-bottom: 50px;
}
.testi-sec-inner{
  padding-top: 130px;
  padding-bottom: 80px;
  background-color: transparent;
  position: relative;
  z-index: 2;
}
.testi-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
/* Add space between items */
.item {
  margin-right: 20px; /* Adjust the margin as needed */
}


.testi-content .rounded-circle{
  width: 150px;
  max-width: 100%;
  margin-bottom: 50px;
}
.testi-content h5{
  color: #041730;
  font-weight: 600;
  text-align: center;
}
.caption-text {
  margin-bottom: 36px;
  text-align: center;
}
.captioner{
  text-transform: uppercase;
  text-align: center;
}
.captioner span{
  font-weight: 600;
}
body .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border: 1px solid #00F090 !important;
    border-radius: 100%;
    margin-right: 7.5px;
    margin-left: 7.5px;
}
body .carousel-indicators .active {
    background:#00F090 !important; 
}
.carousel-indicators{
  margin-bottom: 0 !important;
}
.testi-sec-inner a.primary-link.ml-auto {
    margin-top: 30px;
}

@media (max-width: 767px){
  .testi-sec-inner {
    padding-top: 40px;
    padding-bottom: 40px;
}
  .testi-content {
      flex-direction: column;
  }
  .testi-content .rounded-circle{
    margin-right:0px;
    margin-bottom: 30px;
    max-width: 180px;
  }
  .testi-content .row{
      text-align: center;
  }
  .testi-sec-inner a.primary-link.ml-auto {
      margin-top: 50px;
  }
}


/****************************************
 * life at code-shades css start
 ****************************************/
.lifeAt-cs {
  padding: 100px 0 0;
}
.lifeAt-cs .officeImg {
  border-radius: 10px;
  height: 250px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 15px;
  transform: scale(1);
  position: relative;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
}
.lifeAt-cs .officeImg:hover {
  transform: scale(1.1);
  z-index: 2;
}
.lifeAt-cs h4 {
  margin: 0;
}
.small-para {
  margin: 15px 0 30px;
}
.mr-img img {
  width: 600px;
}
.lifeAt-cs .card {
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 20px 30px;
  box-shadow: 0 0 20px rgba(0,0,0,0.10);
  height: 100%;
  background-color: rgba(255, 255, 255, .25);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  position: relative;
  overflow: hidden;
  text-align: left;
}
.lifeAt-cs .card img {
  position: absolute;
  opacity: 0.07;
}
.onlyLogo {
  right: -30px;
  top: -20px;
  width: 200px;
}
.textLogo {
  bottom: 64px;
  left: -45px;
  transform: rotate(-90deg);
  width: 120px;
}
.lifeAt-cs .sec-title {
  text-align: center;
}
.whyChoose {
  padding-bottom: 100px;
}
.teams {
  background: #fbfbfb;
  padding: 100px 0;
}


/****************************************
 * Contact css start
 ****************************************/
.contactForm {
  padding: 30px 15px;
  transition: cubic-bezier(0.19, 1, 0.22, 1)1s;
}
textarea {
  resize: none;
}
.contactForm input, .form-control {
  border: none;
  color: #185186;
  border-bottom: 1px solid #185186;
  border-radius: 0;
  font-size: 16px;
}
form.contactForm input:focus, .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #185186;
}
form .form-floating>label {
  color: #c6c6c6;
  font-size: 16px;
}
#map {
  height: 100%;
  width: 100%;
  transition: cubic-bezier(0.19, 1, 0.22, 1)2s;
}
.pageBannerHead{
  transition: cubic-bezier(0.19, 1, 0.22, 1)1s;
}
/****************************************
 * portfolio css start
****************************************/
.cs_portfolio {
  padding: 100px 0;
  background-color: #fff;
}

.cs_portfolio .portfolio-content {
  height: 300px;
background-color: #185186;
margin: 10px;
border-radius: 20px;
}

#cs_portfolio.owl-carousel.owl-drag .owl-item {
  padding: 20px 0;
  background-color: #ffffff;
}
.cs_portfolio .portfolio-content img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or object-fit: contain; depending on your design preference */
  padding: 5px;  /* sets padding around the image */
  border-radius: 30px;
}


/****************************************
 * Footer css start
 ****************************************/
footer {
  background: #185186;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.footer-bg {
  position: absolute;
  left: 0;
  opacity: 0.05;
  top: 0;
  z-index: 0;
}
.top-footer-first-row{
  padding-top: 80px;
    padding-bottom: 54px;
    align-items: center;
    justify-content: space-between;
}
.top-footer-first-row-left {
    display: inline-block;
    text-align: center;
    font-size: 14px;
}
.top-footer-first-row-left img {
    margin-bottom: 9px;
}
.top-footer-first-row-left h6{
  color: #ffffff;
  text-transform: uppercase;
}
.top-footer-first-row-middle h3{
  color: #ffffff;
  margin-bottom: 45px;
}
.top-footer-first-row-right{
  text-align: right;
}
.top-footer-second-row{
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid rgba(255,255,255,0.1);
  align-items: center;
  position: relative;
  z-index: 1;
}

.top-footer-second-left address {
  margin: 15px 0 0;
  font-size: 14px;
  font-weight: 400;
  width: 70%;
}
.top-footer-second-left img {
  margin-right: 15px;
}
.top-footer-second-left a:hover {
  color: #ffffff;
  text-decoration: underline;
  opacity: 0.8;
}
.cs-footer-logo {
  max-width: 100px;
}
.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social a {
  border: 1px solid #31B2E9;
  padding: 10px;
  border-radius: 20px 20px 4px 20px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
}

.social a:not(:last-of-type) {
    margin-right: 20px;
}
.social a:hover {
  background: #31B2E9;
  border-radius: 4px;
}
.top-footer-second-row-right {
    background: #31B2E9 ;
    border-radius: 10px;
    padding: 28px 37px;
}
.top-footer-second-row-right h3{
  margin-bottom: 32px;
  color: #ffffff;
}
.top-footer-second-row-right a.link {
    border-bottom: 1px solid;
}
.top-footer-second-row-right a.link:hover, .bottom-footer a:hover{
    color: #31B2E9;
}
.bottom-footer {
  padding-top: 20px;
  padding-bottom: 24px;
  border-top: 1px solid rgba(255,255,255,0.1);
  position: relative;
  z-index: 1;
}
.bottom-footer .col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  position: relative;
}

.item {
  position: relative;
  overflow: hidden; /* Hide overflow for better transition effect */
}

.hovered .hover-text {
  text-align: center;
  display: block;
  transform: translateY(0);
  opacity: 0.7;
}

.hover-text {
  display: none;
  position: absolute;
  bottom: 11px;
  left: 217px;
  transform: translateX(-50%);
  border-radius: 10px;
  color: white;
  padding: 10px;
  background-color: #185186;
  font-family: 'Poppins', sans-serif; 
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Add transition for a smooth effect */
}


.cs-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.short-link {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 20px;
}
.bottom-footer a, .bottom-footer {
  color: #ffffff;
  font-size: 14px;
}
.bottom-footer .short-link:not(:last-of-type):after {
  content: " | ";
  margin: 0 12px;
}
.footer-menu {
  display: flex;
}
.footer-menu li {
  border-left: 1px solid #fff;
  padding: 0 15px;
}
.footer-menu li:first-child {
  border-left: 0;
  padding-left: 0;
}
.footer-menu li a {
  font-size: 14px;
}
.footer-menu li a:hover {
  color: #31B2E9;
}

@media (max-width: 767px){
  .top-footer-first-row-right {
      text-align: left;
  }
  .top-footer-second-row{
    flex-direction: column;
  }
  .top-footer-second-row-right{
    margin-top: 50px;
  }
  .top-footer-second-left {
    margin-left: auto;
    margin-right: auto;
    display: inherit;
  }
  .social {
    margin-top: 30px;
  }
}
.we-surve{
  position: relative;
}

/* Modal Styles */
.modal-dialog {
  min-height: 100vh;
  position: absolute;
  top: 20%;
  left: 15%;
  border-radius: 25px;

}

.modal-content {
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  padding: 20px;
  text-align: center; /* Center-align the text within the modal content */
}

.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  font-family: 'Your Preferred Font Family', sans-serif;
  color: #333;
  margin: 0;
  padding: 10px 0;
}

.modal-body {
  font-size: 1.2rem;
  font-family: 'Your Preferred Font Family', sans-serif;
  color: #666;
  line-height: 1.4;
  text-align: left;
  border-radius: 25px;
}

/* Close Button Styles */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.4rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.modal-close:hover {
  color: #007BFF;
}
/* ContactPage.css */
/* App.css */

.content-container {
  flex-grow: 1; /* Allow the content to grow and fill available space */
}

/* ContactPage.css */

.contact-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.iframe-container {
  max-width: 800px; /* Adjust the width as needed */
  width: 100%;
}
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


@media (max-width: 786px) {
  .cs_portfolio {
    overflow-x: hidden;
  }}